@media only screen and (max-width: 600px) {
    .mobile-header{
        display: block;
        position: fixed;
    }
    .mobile-header .nav-item{
        margin-top: 10px;
    }
    .mobile-header .navbar-toggler .navbar-toggler.active.focus,
    .navbar-toggler.active:focus,
    .navbar-toggler.focus,
    .navbar-toggler.focus:active,
    .navbar-toggler:active:focus,
    .navbar-toggler:focus {
        outline: 0;
        border: none;
        box-shadow: none
    }
    .desktop-header{
        display: none;
    }
    .hero{
        background-position: center right -250px;
    }
    .hero h1{
        font-size: 36px;
    }
    .services .section-header p{
        font-size: 12px;
    }
    .about h3{
        font-size: 28px;
    }
    .about p{
        font-size: 20px;
    }
    .approch .card-approch{
        margin: 0;
        margin-bottom: 10px;
    }
}
  