@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    font-family: "Poppins", sans-serif;
}

.btn-close.active.focus,
.btn-close.active:focus,
.btn-close.focus,
.btn-close.focus:active,
.btn-close:active:focus,
.btn-close:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus,
.navbar-toggler .navbar-toggler.active.focus,
.navbar-toggler.active:focus,
.navbar-toggler.focus,
.navbar-toggler.focus:active,
.navbar-toggler:active:focus,
.navbar-toggler:focus 
.accordion-button .accordion-button.active.focus,
.accordion-button.active:focus,
.accordion-button.focus,
.accordion-button.focus:active,
.accordion-button:active:focus,
.accordion-button:focus {
    outline: 0;
    border: none;
    box-shadow: none
}
.accordion-button:not(.collapsed){
    background-color: #f0f0f0 !important;
}

.mobile-header{
    display: none;
}
body {
    background-color: #fffdfd;
    /* background-color: #000000; */
    color: #292929;
}

.btn-hero {
    background-color: #fffdfd;
    color: #39393d;
    text-decoration: none;
    padding: 15px 80px;
    font-weight: 500;
    z-index: 2;
    font-size: 18px;
    border: 1px solid #818181;
}

.preloader {
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    top : 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(35px);
    -webkit-backdrop-filter: blur(35px);

}

.preloader p {
    margin-top: 15px;
    color: #898989;
}

.preloader .loader {
    display: block;
    --height-of-loader: 5px;
    --loader-color: #040303;
    width: 250px;
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
}

.preloader .loader::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
    ;
}

@keyframes moving {
    50% {
        width: 100%;
    }

    100% {
        width: 0;
        right: 0;
        left: unset;
    }
}

.mt-inside {
    margin-top: 150px !important;
}

.offlinemodeStyle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: fixed;
    background-color: #000000;
}
.offlinemodeStyle h1,.offlinemodeStyle p{
    color: #f0f0f0;

}
.preloader h1 {
    font-size: 53px;
    color: #696969;
    position: absolute;
    z-index: 99999999;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
}

.navbar {
    background-color: #fff !important;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.navbar .nav-link {
    color: #0c0c0c !important;
    font-size: 14px;
    margin-right: 5px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.navbar .navbar-brand {
    font-weight: 600;
    color: #000 !important;
    text-transform: uppercase;
    font-size: 23px;
    font-family: "Barlow Condensed", sans-serif;
}

.navbar-brand-inside {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 23px;
    font-family: "Barlow Condensed", sans-serif;
    text-decoration: none;
    color: #000;
}

.logobrand {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 35px;
    font-family: "Barlow Condensed", sans-serif;
    text-decoration: none;
    color: #ffffff;
}

.navbar .btn-offcanvas {
    border-radius: 50px;
    border: 1px solid #2d2d2d;
    padding: 5px 3px !important;
    background-color: #ffffff;
}

.offcanvas {
    background: rgb(255, 255, 255);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.inside-base-menu {
    list-style: none;
    padding-left: 0;
    color: #0c0c0c;
}

.inside-base-menu a {
    color: #121212;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;   
}

.hero {
    background: #000000;
    background-image: url('https://res.cloudinary.com/dtf5pat7s/image/upload/v1716563573/2150946795_htg73f.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    color: #ffffff;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    padding-bottom: 100px;
    position: relative;
    /* Add this line */
    z-index: 1;
    /* Ensure the hero section is on top of other elements */
}

.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 12, 18, 0.687);
    /* Adjust the opacity as needed */
    z-index: -1;
    /* Ensure the overlay is behind the content of the hero section */
}

.offcanvas .social-links {
    position: absolute;
    bottom: 20px;
}

.offcanvas .social-links h3 {
    font-size: 13px;
    margin-bottom: 20px;
}

.offcanvas .list-social {
    list-style: none;
    padding-left: 0;
}

.offcanvas .social-btn a {
    padding: 10px 20px;
    background-color: #fffdfd;
    margin-right: 10px;
    color: #4a4a4a;
    border: 1px solid #f0f0f0;
}



.mobile-header .social-links h3 {
    font-size: 13px;
    margin-bottom: 20px;
}

.mobile-header .list-social {
    list-style: none;
    padding-left: 0;
}

.mobile-header .social-btn a {
    padding: 10px 20px;
    background-color: #fffdfd;
    margin-right: 10px;
    color: #4a4a4a;
    border: 1px solid #f0f0f0;
}

.hero h1 {
    font-size: 50px;
    font-weight: 600;
    z-index: 2;
    line-height: 1.5;
}

.hero .hero-card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ede8dd;
    padding: 20px;
    color: #000000;
    font-size: 13px;
    border-radius: 3px;
    line-height: 1.8;
    font-weight: 500;
    display: none;
    z-index: 2;
}

.nav-item.dropdown:hover .mega-menu {
    display: block;
}


/* .hero p{
    font-size: 14px;
    line-height: 2;
    color: #d2d2d2;
    font-weight: 500;
} */

.services h2 {
    font-size: 56px;
    font-weight: 500;
}

.services svg {
    height: 40px !important;
    stroke: #3E00FF;
    stroke-width: 1.3;
    margin-top: 10px !important;
}

.about {
    padding-top: 150px;
    padding-bottom: 150px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(15,1,59,1) 100%);
    color: #fff !important;
    margin-top: 50px;
}

.about h3 {
    font-size: 68px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 70px !important;
    color: #fff;
}

.about p {
    font-size: 46px;
    font-weight: 500;
    margin-top: 20px !important;
    margin-bottom: 70px !important;
    transition: 0.5s linear;
}

.about a {
    font-size: 17px;
    text-decoration: none;
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    font-weight: 600;
}

.services .section-header p {
    font-size: 16px;
    font-weight: 500;
}

.approch {
    margin-top: 100px;
}

.approch .section-header h3 {
    font-size: 33px;
    font-weight: 600;
}

.approch .card-approch {
    background-color: #f0f0f0;
    min-height: 280px;
    padding: 10px;
    margin: 10px;
    width: 100%;
    border-radius: 5px;
}

.approch .card-approch span {
    font-size: 53px;
    font-weight: 400;
    opacity: 1;
    color: #3E00FF;
}

.approch h3 {
    font-size: 22px;
    font-weight: 600;
    /* margin-top: 30px; */
    margin-bottom: 15px;
}

.approch p {
    opacity: 0.7;
    font-size: 13px;
    font-weight: 300;
}

.inside-card {
    background-color: #ededed;
    overflow: hidden;
    max-height: 500px;
    min-height: 500px;
    border-radius: 3px;
    padding-bottom: 10px;
    border: 1px solid #e1e1e1;
    transition: 0.3s;
    position: relative;
}

.inside-card:hover{
    background-color: #dcdcdc;
    transition: 0.3s;
    }

.inside-card img {
    margin-bottom: 10px;
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.inside-card span {
    color: #3E00FF;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
}

.inside-card p {
    font-size: 15px;
    color: #2d2d2d;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.inside-card a {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
    margin-bottom: 15px !important;
    position: absolute;
    bottom: 0px;
}

footer {
    margin-top: 30px;
    background: #08090d;
}

footer .slowgan {
    font-size: 14px;
    opacity: 0.5;
    font-weight: 200;
    margin-top: 10px;
}

footer a {
    text-decoration: none;
    color: #c0c0c0;
}

footer h5 {
    font-weight: 600;
    /* border-bottom: 2px solid #fff; */
    margin-bottom: 20px;
    /* padding-bottom: 10px; */
}

footer li {
    color: #c0c0c0 !important;
    margin-bottom: 10px;
}

footer .copyright {
    color: #a2a2a2;
    margin-top: 150px;
    font-size: 12px;
}


.inside-header h2 {
    font-size: 53px;
}

.aboutus-page {
    margin-top: 100px !important;
}

.card {
    background-color: #fff !important;
    color: #000;
    border-radius: 3px !important;
}

.aboutus-page h4 {
    font-weight: 600;
    font-size: 24px;
}

.aboutus-page p {
    font-size: 15px;
    color: #5c5c5c;
}

.aboutus-page img {
    width: 100%;
    border-radius: 5px;
    filter: grayscale(1);
}

.services-page .card {
    margin-bottom: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    border: none;
    min-height: 350px;
    border-bottom: 1px solid #898989;
    border-radius: 0;
    background: #f0f0f0 !important;
}

.services-page .card .card-title {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000000;
}
.services-page .card .card-text {
    font-size: 14px;
    font-weight: 300;
    /* margin-bottom: 10px; */
    color: #606060;
}

.services-page .card svg {
    margin-bottom: 20px !important;
    color: #3E00FF;
}


.login-card {
    /* background-color: #272727; */
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px !important;
}

.notification-error {
    border-radius: 0 !important;
}

.login-section-card h3 {
    font-size: 48px;
    font-weight: 600;
}

.login-section-card p {
    font-size: 16px;
    margin-top: 20px;
    opacity: 0.7;
}

.form-section {
    padding: 20px;
}

.form-section .form-label {
    font-size: 13px;
    font-weight: 400;
}

.form-section p {
    font-size: 12px;
}

.form-control:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: #1b1b1b;
}

.card-services {
    border: 1px solid #d4d4d4;
    min-height: 230px;
    padding: 10px 20px;
    border-radius: 5px;
    position: relative;
}

.services .card-services p {
    font-size: 13px;
    color: #6d6d6d;
    text-wrap: wrap;
    text-overflow: clip;
}
.services .text-data{
    padding:  0  10px 0 0;
    position: absolute;
    bottom: 0;
}

.services .card-services h3 {
    font-size: 21px;
    font-weight: 600;
    margin-top: 20px;
}

.inside-company {
    margin-top: 100px;
}

.CandidateArea-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.CandidateArea-page input {
    /* background-color: #202020; */
    border: 1px solid #d3d3d3;
    color: #000 !important;
    caret-color: #080808 !important;
    border-radius: 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important;

}

input[data-autocompleted] {
    background-color: transparent !important;
    border: 1px solid #d3d3d3 !important;
}

.CandidateArea-page input:focus {
    border: 1px solid #d3d3d3 !important;
    /* background-color: #202020; */
    /* color: #f0f0f0 !important; */
    caret-color: #080808 !important;
}

.CandidateArea-page input::content {
    border: 1px solid #d3d3d3 !important;
    background-color: #202020;
}

.CandidateArea-page .btn-ica {
    background-color: #040303 !important;
    color: #fff;
    font-weight: 500;
    margin-top: 20px;
    padding: 8px 30px;
    border-radius: 3px !important;
}

.CandidateArea-page .btn-ica:hover {
    background-color: #040303 !important;
    color: #fff;
}

.card-interview-section {
    background-color: #f0f0f0;
    border: 2px solid #d9d9d9;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}

.card-interview-section .status{
    position: absolute;
    right: 30px;
    top: 30px;
}
.card-interview-section .status .badge{
    background-color: #000;
    color: #fff;
    padding: 8px 10px;
    font-weight: 500;
    border-radius: 3px;
}
.card-interview-section .status .rejected{
    background-color: red;
}.card-interview-section .status .Scheduled{
    background-color: #7DD181;
    color: #000;
}
.card-interview-section .list-group .list-group-item {
    background: #0e0e0e !important;
}

.interview-area-cd th,
td {
    padding: 5px;
}
.card-interview-section h4{
    font-weight: 600;
}
.card-interview-section .details-head {
    font-size: 13px;
    font-weight: 600;
}
.card-interview-section td strong{
    font-weight: 600;
}

.btn-interview-section {
    background-color: #040303;
    color: #fff;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    outline: none !important;
    border: none !important;
}

.btn-access-area span {
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 600;
    color: #898989;
}
.investor-image{
    height: 300px;
}
.accordion-flush>.accordion-item>.accordion-header .accordion-button, .accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed{
    box-shadow: none !important;
    border: none !important     ;
}
.faq-secction-area .accordion-button{
    background-color: #ffffff;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    
}
.accordion-flush>.accordion-item{
    margin-bottom: 10px;
    border: 0;
    border-bottom: 1px solid #c3c3c3;
}
.faq-secction-area h5{
    margin-top: 30px;
    font-weight: 600;
}

.accordion-body{
    background-color: #ffffff;
    color: #000;
}
.career-page .card{
    background-color: #ececec !important;
    min-height: 300px;
    position: relative;
    border: 0;
}
.career-page .card button{
    position: absolute;
    bottom: 20px;
    background-color: #040303;
    border-radius: 0;
    border: none;
}
.career-page .card p,ul li,h6{
    color: #414141;
    font-size: 13px;
}
.career-page .card .card-title{
    font-weight: 600;
    color: #000000;
    font-size: 20px;
    margin-bottom: 15px;
}
.career-page .modal{
    background: rgba(255, 255, 255, 0.85);
   backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
}

.service-area-about .card{
    background-color: #ededed !important;
    min-height: 350px !important;
    max-height: 350px !important;
    border: none;
    overflow: hidden;
}
.service-area-about .card svg{
    color: #3E00FF;
    margin-bottom: 30px !important;
    stroke-width: 1;
}

.service-area-about .card .card-title{
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}
.service-area-about .card p{
    font-size: 12px;
}
.why-tt .card{
    background-color: #e8e8e8 !important;
    padding: 10px 15px;
    min-height: 220px;
}
.why-tt .card .card-title{
    color: #000;
    font-weight: 600;
}
.why-tt .card p{
    font-size: 13px;
}
.why-tt .card-icon{
    background-color: #000000;
    width: fit-content;
    padding: 15px;
    border-radius: 50px;
    margin-bottom: 20px;
}
.why-tt svg{
    height: 30px;
    stroke: #fff;
}
.support-card{
    background-color: #f7f6f6;
    padding: 50px 30px;
    border-radius: 5px;
    border: 1px solid #e5e2e2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.support-card .btn{
    background-color: #000000;
    border: none;
    border-radius: 3px;
}
.support-card .btn:focus{
    background-color: #000000;
}
.support-card .form-label{
    font-size: 13px;
    font-weight: 500;
}
.support-card input{
    border-radius: 3px;
}
.support-card textarea{
    border-radius: 3px;
}.support-card textarea::placeholder{
    font-weight: 400;
    opacity: 0.4;
    font-size: 14px;
}
.support-card input::placeholder{
    font-weight: 400;
    opacity: 0.4;
    font-size: 14px;
}

.list-group-item-action{
    border: 1px solid #e1e1e1 !important;
    margin-bottom: 10px !important;
}
.contact-page .support-card{
    background-color: #fdfdfd;
    border-color: #f4f4f4;
}
.contact-page ul{
    list-style: none;
    padding-left: 0;
}
.contact-page ul li {
    margin-top: 20px;
}
.contact-page ul li strong,.contact-page ul li a{
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #000;

}

input{
    border-radius: 3px !important;
}
textarea{
    border-radius: 3px !important;
}
textarea::placeholder{
    font-weight: 400;
    opacity: 0.4;
    font-size: 14px;
}
input::placeholder{
    font-weight: 400;
    opacity: 0.4;
    font-size: 14px;
}
.apply-jobs .form-label{
    font-size: 12px;
}